<template>
  <div>
    <transition
      name="page-move"
      v-on:enter="pageEnter"
      v-on:leave="pageLeave"
      v-bind:css="false"
      mode="out-in"
    >
      <keep-alive include="magazineList">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
  import {Power1, Power3, TweenMax} from "gsap";

  export default {
    name: "magazine",
    metaInfo() {
      return {
        title: "Magazine",
        titleTemplate: "%s | e · motion"
      };
    },
    methods: {
      pageEnter(el, done) {
        TweenMax.fromTo(
          el,
          0.5,
          {
            opacity: 0,
            transform: "translateY(20px)"
          },
          {
            opacity: 1,
            transform: "translateY(0)",
            ease: Power3.easeInOut,
            onComplete: () => {
              done();
            }
          }
        );
      },
      pageLeave(el, done) {
        TweenMax.fromTo(
          el,
          0.5,
          {opacity: 1, transform: "translateY(0)"},
          {
            opacity: 0,
            transform: "translateY(-20px)",
            ease: Power1.easeInOut,
            onComplete: () => {
              done();
            }
          }
        );
      }
    }
  };
</script>
<style lang="scss" scoped></style>
